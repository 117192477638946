<template>
	<div>
		<div
			class="status-card"
			:style="{ background, cursor: showIcon ? 'pointer' : 'default' }"
			@click="showDetailStatus"
		>
			<div class="title">
				<i :class="['iconfont', `${cardInfo.icon}`]" :style="{ color }"></i>
				<span>{{ cardInfo.name }}</span>
			</div>
			<div class="value" :style="{ color, 'font-size': fontSize }">
				{{ cardInfo | dealValue }}
			</div>
			<svg v-if="showIcon" class="icon status-card-icon" aria-hidden="true">
				<use xlink:href="#icon-xiangqingbiaoqian"></use>
			</svg>
		</div>
		<el-dialog :title="dialogTitle" :visible.sync="showDetailStatusDialog" :append-to-body="true" width="45%">
			<EchartTable
				ref="echartTable"
				open-type="card"
				:info="cardInfo"
				:show="showDetailStatusDialog"
				entry="detail"
			></EchartTable>
		</el-dialog>
	</div>
</template>

<script>
import { isBlank } from '@/utils/validate'
import EchartTable from './ChartTable.vue'

export default {
	name: 'statusCard',
	components: { EchartTable },
	props: {
		cardInfo: {
			type: Object,
			default: () => {},
		},
	},
	filters: {
		dealValue(cardInfo) {
			let val = '暂无数据'
			if (!isBlank(cardInfo.value)) {
				val = cardInfo.unitName ? cardInfo.value + cardInfo.unitName : cardInfo.value
			}
			return val
		},
	},
	data() {
		return {
			styleMap: {
				1: {
					background: 'linear-gradient(135deg, #f5f6fc 0%, #edeffa 100%)',
					color: '#4d6bff',
				},
				2: {
					background: 'linear-gradient(135deg, #faf7f6 0%, #f5eeeb 100%)',
					color: '#e5662e',
				},
			},
			showDetailStatusDialog: false,
		}
	},
	computed: {
		background() {
			let map = this.cardInfo.style ? this.styleMap[this.cardInfo.style] : this.styleMap['2']
			return map.background
		},
		color() {
			let color
			if (isBlank(this.cardInfo.value)) {
				color = '#A1A4B3'
			} else {
				let map = this.cardInfo.style ? this.styleMap[this.cardInfo.style] : this.styleMap['2']
				color = map.color
			}
			return color
		},
		fontSize() {
			return !isBlank(this.cardInfo.value) && (this.cardInfo.valueType == 1 || this.cardInfo.valueType == 3)
				? '19px'
				: '22px'
		},
		dialogTitle() {
			return this.cardInfo.name + '变化'
		},
		showIcon() {
			let { timeSeriesDesc, value } = this.cardInfo
			return timeSeriesDesc?.drawType?.length && value ? true : false
		},
	},
	methods: {
		showDetailStatus() {
			if (this.showIcon) {
				this.$refs.echartTable?.resetTime()
				this.showDetailStatusDialog = true
				console.log(this.cardInfo)
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.status-card {
	position: relative;
	min-width: 238px;
	height: 100px;
	padding: 20px 20px 10px;
	margin: 20px 20px 0 0;
	border-radius: 6px;
	box-sizing: border-box;
	.title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 16px;
		color: #4e4e4e;
		margin-bottom: 18px;
		.icon {
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
		.iconfont {
			font-size: 24px;
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
	}
	.value {
		font-family: DINAlternate-Bold, DINAlternate;
		font-size: 18px;
		font-weight: bold;
	}
	.status-card-icon {
		position: absolute;
		top: 0;
		right: 0;
		width: 32px;
		height: 32px;
		border-radius: 0 6px 0 0;
	}
}
::v-deep .el-dialog {
	.el-dialog__title {
		font-size: 20px;
		color: #4d4d4d;
	}
	.el-dialog__body {
		padding: 22px 25px 38px;
	}
}
</style>
