<template>
	<div class="config-info">
		<div class="tab-box">
			<span
				v-if="showDeviceRule"
				:class="{
					active: active === 'device',
				}"
				@click="active = 'device'"
			>
				设备规则配置
			</span>
			<span
				:class="{
					active: active === 'vee',
					border: !showDeviceRule,
				}"
				@click="active = 'vee'"
			>
				VEE规则
			</span>
		</div>
		<div class="table-box">
			<device-rule v-show="active === 'device'" :tabData="tabData" />
			<vee-rule v-show="active === 'vee'" :tabData="tabData" />
		</div>
	</div>
</template>

<script>
import DeviceRule from './DeviceRule.vue'
import VeeRule from './VeeRule.vue'

export default {
	components: { DeviceRule, VeeRule },
	name: 'ConfigInfo',
	props: {
		tabData: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			active: 'vee', //device-设备规则配置 vee-VEE规则
			showDeviceRule: false,
		}
	},
	created() {
		if (this.tabData?.deviceTypeId == 906) {
			this.active = 'device'
			this.showDeviceRule = true
		}
	},
	mounted() {},
	methods: {},
}
</script>
<style lang="scss" scoped>
.config-info {
	padding: 24px;
	display: flex;
	flex-direction: column;
	height: 100%;
	.tab-box {
		padding-bottom: 20px;
		span {
			display: inline-block;
			width: 120px;
			text-align: center;
			line-height: 30px;
			border: 1px solid #6783ff;
			color: #4d6bff;
			cursor: pointer;
		}
		span:nth-child(1) {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}
		span:nth-child(2) {
			border-left: none;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
		.active {
			background: #6783ff;
			color: #fff;
		}
		.border {
			border-radius: 4px;
		}
	}
	.table-box {
		flex: 1;
		height: 0;
	}
}
</style>
