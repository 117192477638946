<template>
	<div class="relation-device-box" v-loading.fullscreen.lock="loading">
		<gc-dialog
			width="900px"
			:show.sync="visible"
			title="新增关联设备"
			@ok="handleOk"
			@cancel="handleClose"
			@close="handleClose"
		>
			<el-form class="relation-device-form" ref="form" :model="form" :rules="formRules" label-position="top">
				<el-form-item label="监测类型" prop="subDeviceCategory">
					<el-radio-group v-model="form.subDeviceCategory" @change="handleRadioChange">
						<el-radio :label="0">设备</el-radio>
						<el-radio :label="1">压力点</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="通道号" prop="subChannelNo">
					<!-- <el-select v-model="form.subChannelNo" placeholder="请选择">
            <el-option
              v-for="item in subChannelNoOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
					<el-input
						placeholder="请输入通道号"
						v-model="form.subChannelNo"
						type="text"
						@change="handleSubChannelNoChange"
					/>
				</el-form-item>
				<el-form-item
					label="设备类型"
					prop="subDeviceTypeName"
					key="subDeviceTypeName"
					v-if="form.subDeviceCategory == 0"
				>
					<el-input v-model="form.subDeviceTypeName" disabled placeholder="自动填充" />
				</el-form-item>
				<el-form-item label="压力监测点" prop="pressureStationName" key="pressureStationName" v-else>
					<el-input v-model="form.pressureStationName" placeholder="请填写" />
				</el-form-item>
				<div class="device" v-if="form.subDeviceCategory == 0" key="device-box">
					<el-form-item label="设备编号" prop="subDeviceNo">
						<el-input v-model="form.subDeviceNo" disabled placeholder="自动填充" />
					</el-form-item>
					<el-form-item label="厂商名称" prop="manufacturerName">
						<el-input v-model="form.manufacturerName" disabled placeholder="自动填充" />
					</el-form-item>
					<el-form-item label="型号" prop="model">
						<el-input v-model="form.model" disabled placeholder="自动填充" />
					</el-form-item>
				</div>
				<div class="pressure-point" v-else key="pressure-box">
					<el-form-item label="监测位置" prop="detectLocation">
						<el-select v-model="form.detectLocation" placeholder="请选择">
							<el-option
								v-for="item in detectLocationOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
				</div>
			</el-form>
		</gc-dialog>
	</div>
</template>

<script>
import { ruleRequired, ruleMaxLength, ruleOnlyInteger, ruleLt } from '@/utils/rules'
import { apiGetSubDeviceDetail, apiAddRelationDevice } from '@/apis/account.api'
import { isBlank } from '@/utils/validate'

export default {
	name: 'RelationDeviceDialog',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		// detail: {
		//   type: Object,
		//   default: null,
		// },
		tabData: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			form: {
				subDeviceCategory: 0, //监测类型
				subChannelNo: '', //通道号
				pressureStationName: '', //压力监测点
				detectLocation: '', //监测位置
				// 自动填充-设备字段
				subDeviceId: '', //子设备id
				subDeviceTypeName: '', //设备类型名称
				subDeviceNo: '', //设备编号
				manufacturerName: '', //厂商名称
				model: '', //型号
			},
			formRules: {
				subDeviceCategory: [ruleRequired('请选择监测类型')],
				subChannelNo: [ruleRequired('请输入通道号'), ruleOnlyInteger, ruleLt(0)],
				pressureStationName: [ruleRequired('请输入压力监测点'), ruleMaxLength(40)],
				detectLocation: [ruleRequired('请选择监测位置')],
			},
			subChannelNoOptions: [
				{
					value: 1,
					label: '通道1',
				},
				{
					value: 2,
					label: '通道2',
				},
				// {
				//   value: 3,
				//   label: "通道3",
				// },
				// {
				//   value: 4,
				//   label: "通道4",
				// },
			],
			detectLocationOptions: [
				{
					value: '11',
					label: '进口',
				},
				{
					value: '12',
					label: '出口',
				},
			],
			loading: false,
		}
	},
	computed: {
		visible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},
	},
	watch: {
		// 通道号
		// "form.subChannelNo"(val) {
		// },
	},
	created() {},
	mounted() {},
	methods: {
		handleSubChannelNoChange(val) {
			console.log('subChannelChange: ', val)
			this.$refs.form.validateField('subChannelNo', err => {
				if (err) return
				// 监测类型为设备，选择通道号，获取设备信息
				if (!isBlank(val) && this.form.subDeviceCategory == 0) {
					this.loading = true
					apiGetSubDeviceDetail({
						subChannelNo: val,
						gatewayDeviceId: this.tabData?.deviceId,
					})
						.then(res => {
							this.loading = false
							if (res) {
								Object.entries(res).forEach(([key, value]) => {
									if (Object.prototype.hasOwnProperty.call(this.form, key)) {
										this.form[key] = !isBlank(value) ? value : ''
									}
								})
							}
						})
						.catch(() => {
							this.loading = false
							//设备不存在
							this.form.subDeviceId = '' //子设备id
							this.form.subDeviceTypeName = '' //设备类型名称
							this.form.subDeviceNo = '' //设备编号
							this.form.manufacturerName = '' //厂商名称
							this.form.model = '' //型号
						})
				}
			})
		},
		handleOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					// 当前选择的通道无设备，则无法添加
					if (this.form.subDeviceCategory == 0 && isBlank(this.form.subDeviceId)) {
						this.$message.error('当前通道无设备，请先去新增设备')
						return false
					}
					this.loading = true
					apiAddRelationDevice({
						...this.form,
						gatewayDeviceId: this.tabData?.deviceId,
					})
						.then(() => {
							this.loading = false
							this.$message.success('新增关联设备成功！')
							// 设备变化
							this.$store.commit('account/SET_RELATION_DEVICE_CHANGE', true)
							this.$emit('refresh')
							this.visible = false
						})
						.catch(err => {
							this.loading = false
							console.error(err)
						})
				}
			})
		},
		handleClose() {
			this.form = this.$options.data().form
			this.$nextTick(() => {
				this.$refs.form.clearValidate()
				this.visible = false
			})
		},
		// 监测类型切换
		handleRadioChange(value) {
			this.form = this.$options.data().form
			this.form.subDeviceCategory = value
			this.$nextTick(() => {
				this.$refs.form.clearValidate()
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.relation-device-form {
	display: flex;
	flex-wrap: wrap;
	.device,
	.pressure-point {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.el-form-item {
		width: 30%;
	}
	.el-form-item:not(:nth-child(3n + 1)) {
		margin-left: 40px;
	}
}
</style>
