<template>
	<div class="device-account-detail" v-loading.lock="loading">
		<div class="customer-info" v-if="false">
			<CustomerInfoModal />
		</div>
		<div class="device-info">
			<gc-header :header-data="headerData">
				<template #status>
					<span :class="['status', status.class]">{{ status.text }}</span>
				</template>
				<template #ops>
					<send-command
						v-if="judgeBtnShow(detail.deviceStatus, 'directive') && hasCmdmodel"
						source="detail"
						btn-text="指令下发"
						:device-type-id="detail.deviceTypeId"
						:device-id="deviceId"
					></send-command>
					<el-button
						v-click-blur
						v-if="judgeBtnShow(detail.deviceStatus, 'install')"
						type="primary"
						@click="installDevice"
					>
						设备安装
					</el-button>
					<el-button
						v-click-blur
						v-if="judgeBtnShow(detail.deviceStatus, 'change')"
						type="warning"
						plain
						@click="changeDevice"
					>
						修改信息
					</el-button>
					<el-button
						v-click-blur
						v-if="judgeBtnShow(detail.deviceStatus, 'uninstall')"
						type="warning"
						plain
						@click="uninstallDevice"
					>
						设备拆除
					</el-button>
					<el-button
						v-click-blur
						v-if="judgeBtnShow(detail.deviceStatus, 'delete')"
						type="warning"
						plain
						@click="deleteDevice"
					>
						设备删除
					</el-button>
				</template>
			</gc-header>
			<div class="tab-box">
				<gc-detail-tab
					:common="deviceId"
					:tab-list="tabList"
					:default-active-name.sync="defaultActiveName"
					@qury-count="quryCountFromAlarmRecord"
				></gc-detail-tab>
			</div>
		</div>
		<gc-dialog
			ref="dialogBox"
			:width="dialogType === 'uninstall' ? '480px' : '900px'"
			:show.sync="showDialog"
			:title="title"
			@ok="okDialog"
			@cancel="cancelDialog"
			@close="closeDialog"
		>
			<DialogUninstall
				ref="dialogUninstall"
				v-if="dialogType === 'uninstall'"
				:params.sync="dialogParams"
			></DialogUninstall>
			<DialogContent
				ref="dialogContent"
				v-else
				:params.sync="dialogParams"
				:type="dialogType"
				:change-type="dialogChangeType"
				:show="showDialog"
				@send-dynomic-keys="getDynomicKeys"
			></DialogContent>
		</gc-dialog>
	</div>
</template>

<script>
import AlarmRecord from './tabs/alarm-record'
import BasicInfo from './tabs/basic-info'
import CustomerInfoModal from '../../customer-manage/component/CustomerInfoModal'
import CollectRecord from './tabs/collect-record'
import DirectiveRecord from './tabs/directive-record'
import OperateRecord from './tabs/operate-record'
import CommunicaitionRecord from './tabs/communicaition-record'
import RelationDevice from './tabs/relation-device'
import ChannelInformation from './tabs/channel-information'
import RemoteSecurityCheck from './tabs/remote-security-check'
import ConfigInfo from './tabs/config-info'
import DialogContent from '../account/DialogContent'
import DialogUninstall from '../account/DialogUninstall'
import sendCommand from '@/pages/run-manage/batch-directive-manage/send-command/index.vue'
import {
	apiAlarmCount,
	apiDeleteDevice,
	apiUninstallDevice,
	apiChangeDevice,
	apiGetDeviceDetail,
	apiInstallDevice,
	apiGetAbilityCmdmodelList,
} from '@/apis/account.api'
import { apiGetTenantConfigs } from '@/apis/tenant.api.js'
import { handleParams } from '@/utils'
import { isBlank } from '@/utils/validate'
import { mapGetters } from 'vuex'

export default {
	name: 'deviceDetail',
	components: {
		DialogContent,
		DialogUninstall,
		sendCommand,
		CustomerInfoModal,
	},
	props: {
		deviceIdFromOut: {
			type: [String, Number],
			default: '',
		},
	},
	data() {
		return {
			dynomicKeys: [],
			showDialog: false,
			title: '设备新增',
			dialogParams: {},
			dialogType: 'add',
			detail: {},
			alarmCount: 0,
			loading: false, //加载loading
			dialogChangeType: 0,
			hasCmdmodel: false,
			defaultActiveName: 'BasicInfo',
			needHideCommunication: false,
		}
	},
	computed: {
		...mapGetters({
			userInfo: 'userInfo',
			dataList: 'dataList',
		}),
		headerData() {
			let title = this.detail.deviceTypeName
			if (this.userInfo.tenantType == 1) {
				title =
					(this.dataList.userType.find(item => item.defaultValue == this.detail.userType) || {}).name || '--'
			}
			return {
				pic: 'account-detail', //url
				title, //标题
				desc: [
					{
						icon: 'icon-bianhao',
						text: this.detail.deviceNo,
					},
					{
						icon: 'icon-daohangdizhi',
						text: this.detail.addressName,
						maxWidth: '40%',
					},
				],
			}
		},
		status() {
			const map = {
				0: {
					text: '待安装',
					class: 'wait-installed',
				},
				1: {
					text: '已安装',
					class: 'installed',
				},
				2: {
					text: '已拆除',
					class: 'had-uninstalled',
				},
			}
			return {
				text: map[this.detail.deviceStatus]?.text || '',
				class: map[this.detail.deviceStatus]?.class || '',
			}
		},
		deviceId() {
			return this.$route.query?.deviceId || this.deviceIdFromOut
		},
		tabList() {
			let tabArr = [
				{
					name: 'BasicInfo',
					label: '基本信息',
					component: BasicInfo,
					disabled: false,
					data: this.detail,
				},
				{
					name: 'AlarmRecord',
					label: '告警记录',
					component: AlarmRecord,
					disabled: this.detail.deviceStatus !== 1,
					keyWord: 'monitor:device:account:record:alarm',
					needTag: this.detail.deviceStatus == 1 && this.alarmCount > 0,
					tagNum: this.alarmCount,
				},
				{
					name: 'DirectiveRecord',
					label: '指令记录',
					component: DirectiveRecord,
					disabled: this.detail.deviceStatus !== 1,
					data: this.detail,
					keyWord: 'monitor:device:account:record:command',
				},
				{
					name: 'CollectRecord',
					label: '采集记录',
					component: CollectRecord,
					disabled: this.detail.deviceStatus !== 1,
					data: this.detail,
					keyWord: 'monitor:device:account:record:collect',
				},
				{
					name: 'OperateRecord',
					label: '操作日志',
					component: OperateRecord,
					keyWord: 'monitor:device:account:record:operate',
				},
				{
					name: 'CommunicaitionRecord',
					label: '通讯记录',
					component: CommunicaitionRecord,
					disabled: this.detail.deviceStatus !== 1,
					keyWord: 'monitor:device:account:record:communicate',
					needHide: this.needHideCommunication,
					data: this.detail,
				},
			]
			if (this.detail.deviceTypeId === 1013) {
				tabArr.splice(4, 0, {
					name: 'RemoteSecurityCheck',
					label: '远程安检',
					component: RemoteSecurityCheck,
					disabled: false,
					data: this.detail,
					keyWord: 'monitor:device:account:record:collect',
				})
			}
			if (this.detail.connectType == 1) {
				tabArr.splice(
					1,
					0,
					{
						name: 'RelationDevice',
						label: '关联设备',
						component: RelationDevice,
						disabled: this.detail.deviceStatus !== 1,
						keyWord: 'monitor:device:account:sub-device:maintain',
						data: this.detail,
					},
					{
						name: 'ChannelInformation',
						label: '通道信息',
						component: ChannelInformation,
						disabled: this.detail.deviceStatus !== 1,
						keyWord: 'monitor:device:account:category:history',
						data: this.detail,
					},
				)
			}
			// 密闭空间可燃气体监测仪 配置信息
			// if (this.detail.deviceTypeId == 906) {
			if (this.detail.hasVee == 1) {
				tabArr.splice(1, 0, {
					name: 'ConfigInfo',
					label: '配置信息',
					component: ConfigInfo,
					disabled: this.detail.deviceStatus !== 1,
					// 权限
					// keyWord: "monitor:device:account:sub-device:maintain",
					data: this.detail,
				})
			}
			return tabArr
		},
	},
	watch: {
		deviceId: {
			immediate: true,
			handler(val) {
				if (val) {
					this.getAlarmCount(val)
					this.getDeviceDetail(val)
					//关联设备（详情页跳转详情页）-刷新
					if (this.$route.params?.reload) {
						const { deviceId, deviceType } = this.$route.query
						this.$router.replace({
							path: 'blank',
							name: 'Blank',
							query: {
								deviceId,
								deviceType,
							},
						})
					}
				}
			},
		},
	},
	created() {
		if (this.$route.params?.name) {
			this.defaultActiveName = this.$route.params.name
		}
		if (this.$has('monitor:device:account:command:insert')) {
			apiGetAbilityCmdmodelList().then(res => {
				let deviceType = this.$route.query?.deviceType
				res.forEach(item => {
					if (item.deviceType === Number(deviceType)) {
						this.hasCmdmodel = item.cmdModelList.length
					}
				})
			})
		}
		apiGetTenantConfigs({ tenantId: this.userInfo.tenantId }).then(res => {
			let list = res.dataList || []
			list.forEach(item => {
				if (item.key === 'enable_communication_records') {
					//租户配置如果没有通讯记录则隐藏通讯记录tab
					this.needHideCommunication = item.value === 'false'
				}
			})
		})
	},
	methods: {
		judgeBtnShow(val, type) {
			if (this.deviceIdFromOut) {
				return false
			}
			const map = {
				0: ['install', 'change', 'delete'],
				1: ['directive', 'change', 'uninstall'],
				2: ['install', 'delete'],
			}
			const authMap = {
				directive: 'monitor:device:account:command:insert',
				install: 'monitor:device:account:maintain',
				change: 'monitor:device:account:maintain',
				uninstall: 'monitor:device:account:maintain',
				delete: 'monitor:device:account:maintain',
			}
			return map[val]?.includes(type) && this.$has(authMap[type])
		},
		quryCountFromAlarmRecord() {
			this.getAlarmCount(this.deviceId)
		},
		// 获取设备报警数
		getAlarmCount(deviceId) {
			if (!this.$has('monitor:device:account:record:alarm')) return
			apiAlarmCount(deviceId).then(res => {
				this.alarmCount = res.count
			})
		},
		// 获取设备详情
		getDeviceDetail(deviceId) {
			this.loading = true
			apiGetDeviceDetail(deviceId)
				.then(res => {
					this.detail = res
				})
				.finally(() => {
					this.loading = false
				})
		},
		dealReRenderData(row) {
			let obj = {}
			for (var key in row) {
				let val = row[key]
				if (!isBlank(val)) {
					if (key === 'installDate') {
						obj[key] = new Date(val)
					}
					// else if (key === "latitude") {
					//   // obj["lngLat"] = `经度${row.longitude || ""}度，纬度${
					//   //   row.latitude || ""
					//   // }度`;
					//   obj[key] = val;
					// }
					else {
						obj[key] = val
					}
				}
				obj.lngLat = {
					lng: row.longitude || undefined,
					lat: row.latitude || undefined,
				}
			}
			return obj
		},
		// 修改信息
		changeDevice() {
			this.title = '设备修改'
			this.dialogParams = this.dealReRenderData(this.detail)
			this.dialogType = 'change'
			this.dialogChangeType = this.dialogParams.deviceStatus
			this.showDialog = true
		},
		// 设备拆除
		uninstallDevice() {
			this.title = '设备拆除'
			this.dialogType = 'uninstall'
			this.showDialog = true
		},
		// 设备安装
		installDevice() {
			this.title = '设备安装'
			this.dialogParams = this.dealReRenderData(this.detail)
			this.dialogType = 'install'
			this.$set(this.dialogParams, 'deviceStatus', 1)
			this.showDialog = true
		},
		// 设备删除
		deleteDevice() {
			this.$confirm('你确定要删除该设备吗？删除后将不可撤销。').then(() => {
				apiDeleteDevice(this.detail.iotDeviceId).then(() => {
					this.$message.success('设备删除成功')
					this.$store.dispatch('account/changeStatus', true)
					this.$router.replace({
						path: 'account',
						params: { needRefresh: true },
					})
				})
			})
		},
		getDynomicKeys(list) {
			this.dynomicKeys = list
		},
		okDialog() {
			let params = {
				dynamicContent: {},
			}
			for (var key in this.dialogParams) {
				let val = handleParams(this.dialogParams)[key]
				// dynomicKeys 为空传null或者''
				if (this.dynomicKeys.includes(key)) {
					if (val instanceof Date) {
						if (!isBlank(val)) {
							params.dynamicContent[key] = this.dayjs(val).format('YYYY-MM-DD')
						} else {
							params.dynamicContent[key] = null
						}
					} else {
						params.dynamicContent[key] = !isBlank(val) ? val : null
					}
				} else {
					if (!isBlank(val)) {
						if (val instanceof Date) {
							params[key] = this.dayjs(val).format('YYYY-MM-DD')
						} else {
							params[key] = val
						}
					}
				}
			}
			switch (this.dialogType) {
				case 'uninstall':
					this.$refs.dialogUninstall?.$refs.uninstallForm.validate(valid => {
						if (valid) {
							params = {
								deviceId: this.deviceId,
								uninstallReason: this.dialogParams.uninstallReason,
								uninstallDate: this.dayjs(this.dialogParams.uninstallDate).format('YYYY-MM-DD'),
							}
							this.loading = true
							apiUninstallDevice(params)
								.then(() => {
									this.$store.dispatch('account/changeStatus', true)
									this.showDialog = false
									this.$message.success('设备拆除成功')
									this.getDeviceDetail(this.deviceId)
								})
								.finally(() => {
									this.loading = false
								})
						}
					})
					return
				case 'change':
					this.$refs.dialogContent?.$refs.contentForm?.validate((valid, obj) => {
						let lackList = Object.keys(obj)
						if (lackList.length) {
							this.$refs?.dialogContent?.showValidFalse(lackList[0])
						}
						if (valid && this.$refs.dialogContent.validateUseGas()) {
							this.loading = true
							apiChangeDevice(params)
								.then(() => {
									this.$store.dispatch('account/changeStatus', true)
									this.showDialog = false
									this.$message.success('设备修改成功')
									this.getAlarmCount(this.deviceId)
									this.getDeviceDetail(this.deviceId)
								})
								.finally(() => {
									this.loading = false
								})
						}
					})
					return
				case 'install':
					this.$refs.dialogContent?.$refs.contentForm?.validate((valid, obj) => {
						let lackList = Object.keys(obj)
						if (lackList.length) {
							this.$refs?.dialogContent?.showValidFalse(lackList[0])
						}
						if (valid && this.$refs.dialogContent.validateUseGas()) {
							this.loading = true
							apiInstallDevice(params)
								.then(() => {
									this.$store.dispatch('account/changeStatus', true)
									this.showDialog = false
									this.$message.success('设备安装成功')
									this.getAlarmCount(this.deviceId)
									this.getDeviceDetail(this.deviceId)
								})
								.finally(() => {
									this.loading = false
								})
						}
					})
					return
			}
			this.showDialog = false
		},
		cancelDialog() {
			this.showDialog = false
		},
		closeDialog() {
			this.$nextTick(() => {
				this.$refs.dialogUninstall?.$refs.uninstallForm.clearValidate()
				let dialogContent = this.$refs.dialogContent
				if (dialogContent) {
					dialogContent.$refs.contentForm.clearValidate()
					dialogContent.resetUseGas()
					if (dialogContent.showMapBox) {
						dialogContent.showMapBox = false
						dialogContent.$refs.mapSelect.initMap()
					}
				}
			})
			this.showDialog = false
			this.dialogParams = {}
		},
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (vm.$route.query.from === 'custom') {
				to.meta.customCrumb = [
					{ customCrumbTitle: '客户管理', path: '/customer-manage' },
					{ customCrumbTitle: '设备详情' },
				]
			} else {
				to.meta.customCrumb = [
					{ customCrumbTitle: '设备管理', path: '/device' },
					{ customCrumbTitle: '设备详情' },
				]
			}
		})
	},
}
</script>
<style lang="scss" scoped>
.device-account-detail {
	@include base-button(100px);
	height: 100%;
	display: flex;
	.customer-info {
		height: 100%;
		padding: 12px;
	}
	.device-info {
		flex: 1;
		width: 0;
		display: flex;
		flex-direction: column;
	}
	.tab-box {
		flex: 1;
		height: 0;
	}
	.installed {
		background: #e4f5ec;
		color: #16a65d;
		&::before {
			position: absolute;
			top: 8px;
			left: 10px;
			display: block;
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #16a65d;
		}
	}
	.wait-installed {
		background: #f5e9e4;
		color: #e5662e;
		&::before {
			position: absolute;
			top: 8px;
			left: 10px;
			display: block;
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #e5662e;
		}
	}
	.had-uninstalled {
		background: #dbdbdb;
		color: #8f8f8f;
		&::before {
			position: absolute;
			top: 8px;
			left: 10px;
			display: block;
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #8f8f8f;
		}
	}
}
</style>
