<template>
	<div class="vee-table">
		<gc-table :showPage="false" border :columns="columns" :table-data="tableData"></gc-table>
		<!-- 修改弹窗 -->
		<gc-dialog
			:show.sync="dialogShow"
			@cancel="handleCancel"
			@close="handleCancel"
			@ok="submitForm"
			title="修改规则"
		>
			<el-form
				:model="ruleForm"
				:rules="{}"
				ref="ruleForm"
				label-width="100px"
				class="demo-ruleForm"
				label-position="top"
			>
				<el-form-item label="功能名称" prop="eventName">
					<el-input v-model="ruleForm.eventName" disabled></el-input>
				</el-form-item>
				<el-form-item label="规则描述" prop="ruleDescription">
					<el-input v-model="ruleForm.ruleDescription" disabled></el-input>
				</el-form-item>

				<el-form-item label="单位" prop="unitName" key="unitName" v-if="ruleForm.isConfig == 1">
					<el-input v-model="ruleForm.unitName" disabled></el-input>
				</el-form-item>
				<el-form-item
					label="配置值"
					prop="comparisonValue"
					key="comparisonValue"
					v-if="ruleForm.isConfig == 1"
					:rules="[
						ruleRequired('请输入配置值'),
						!isBlank(ruleForm.comparisonValueReg)
							? {
									pattern: ruleForm.comparisonValueReg,
									message: !isBlank(ruleForm.comparisonValueRegMsg)
										? ruleForm.comparisonValueRegMsg
										: '',
									trigger: '',
							  }
							: '',
					]"
				>
					<el-input v-model="ruleForm.comparisonValue" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="状态" prop="state" :rules="[ruleRequired('选择规则状态')]">
					<el-radio-group v-model="ruleForm.state">
						<el-radio label="1">启用</el-radio>
						<el-radio label="0">停用</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
		</gc-dialog>
	</div>
</template>

<script>
import { isBlank } from '@/utils/validate'
import { ruleRequired } from '@/utils/rules'
import { apiGetVeeRuleConfig, apiSetVeeRuleConfig } from '@/apis/account.api.js'

export default {
	name: 'VeeRule',
	props: {
		tabData: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			tableData: [],
			columns: [
				{
					key: 'eventName',
					name: '功能名称',
				},
				{
					key: 'ruleDescription',
					name: '规则描述',
				},
				{
					key: 'comparisonValue',
					name: '配置值',
				},
				{
					key: 'unitName',
					name: '单位',
				},
				{
					key: 'state',
					name: '状态',
					render: (h, row) => {
						return h('span', {}, !isBlank(row.state) ? (row.state == 0 ? '停用' : '启用') : '--')
					},
				},
				{
					key: 'ops',
					name: '操作',
					render: (h, row) => {
						return h(
							'span',
							{
								style: {
									color: '#4d6bff',
									cursor: 'pointer',
									'font-size': '14px',
								},
								on: {
									click: () => {
										Object.assign(this.ruleForm, row)
										this.dialogShow = true
									},
								},
							},
							'修改',
						)
					},
				},
			],
			dialogShow: false,
			ruleForm: {
				eventName: '',
				ruleDescription: '',
				comparisonValue: '',
				unitName: '',
				state: '',
			},
		}
	},
	watch: {
		tabData: {
			handler(val) {
				if (Object.keys(val).length > 0) {
					this.getVeeList()
				}
			},
			immediate: true,
		},
	},
	created() {},
	mounted() {},
	methods: {
		ruleRequired,
		isBlank,
		submitForm() {
			this.$refs.ruleForm.validate(valid => {
				if (valid) {
					apiSetVeeRuleConfig({
						...this.ruleForm,
					}).then(() => {
						this.$message.success('修改成功！')
						this.dialogShow = false
						this.getVeeList()
					})
				} else {
					return false
				}
			})
		},
		handleCancel() {
			this.ruleForm = this.$options.data().ruleForm
			this.$nextTick(() => {
				this.$refs.ruleForm.resetFields()
				this.dialogShow = false
			})
		},
		getVeeList() {
			const { deviceId } = this.tabData
			apiGetVeeRuleConfig({
				deviceId,
			}).then(res => {
				this.tableData = res.records || []
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.vee-table {
	height: 100%;
}
</style>
