<template>
	<div class="pressure-tap" v-show="activeChart === 'pressure'">
		<div class="left-box">
			<div class="device-show" v-if="deviceDataList.length > 0">
				<div class="device-item" v-for="item in deviceDataList" :key="item.subDeviceId">
					<div class="device-item-name">{{ item.pressureStationName }}</div>
					<div class="device-item-info">
						<div class="label-value">
							<span class="label">{{ item.detectLocation == 11 ? '进口' : '出口' }}压力：</span>
							<span class="value">
								{{ !isBlank(item.pressure) ? item.pressure + pressureUnitName : '--' }}
							</span>
						</div>
						<div class="label-value">
							<span class="label">{{ item.detectLocation == 11 ? '进口' : '出口' }}温度：</span>
							<span class="value">
								{{ !isBlank(item.gasTemperature) ? item.gasTemperature + '℃' : '--' }}
							</span>
						</div>
						<div class="label-value row">
							<span class="label">通讯时间：</span>
							<span class="value">
								{{ !isBlank(item.communicateTime) ? item.communicateTime : '--' }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<gc-empty v-else pageType="channelInfo"></gc-empty>
		</div>
		<div class="right-box">
			<div class="right-box-title">
				<span class="title-text">历史查询</span>
				<!-- <div class="list-chart">
          <span
            :class="{
              active: activeForm === 'chart',
            }"
            @click="() => (activeForm = 'chart')"
          >
            <i class="iconfont icon-tubiao"></i>
          </span>
          <span
            :class="{
              active: activeForm === 'list',
            }"
            @click="() => (activeForm = 'list')"
          >
            <i class="iconfont icon-liebiao"></i>
          </span>
        </div> -->
			</div>
			<div class="pressure-tap-search">
				<gc-custom-search
					class="search"
					width="450px"
					key-word="时间区间"
					type="date"
					:search.sync="params['time']"
					date-picker-type="datetimerange"
				>
					<template v-slot:icon>
						<i class="iconfont icon-riqi"></i>
					</template>
				</gc-custom-search>
				<div class="ops-btn">
					<el-button type="primary" v-click-blur @click="getData(activeChart)">查询</el-button>
					<el-button v-click-blur @click="reset(activeChart)">重置</el-button>
				</div>
			</div>
			<div class="pressure-tap-echarts-box" ref="pressure-tap-echarts-box">
				<!-- :style="{
            width: pressureTapWidth + 'px',
            height: pressureTapHeight + 'px',
            'min-height': '400px',
          }" -->
				<div
					:id="'pressure' + deviceId"
					:style="{
						width: '100%',
						height: '100%',
					}"
					v-if="echartsFlag"
				></div>
				<gc-empty v-else></gc-empty>
			</div>
		</div>
	</div>
</template>

<script>
const echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
require('echarts/lib/component/grid')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/visualMap')
require('echarts/lib/component/dataZoom')
require('echarts/lib/component/markLine')
require('echarts/lib/component/markPoint')
require('echarts/lib/component/legend')
require('echarts/lib/component/toolbox')
let elementResizeDetectorMaker = require('element-resize-detector')

import { isBlank } from '@/utils/validate'
import chartMix from './mixin'

export default {
	name: 'PressureTap',
	mixins: [chartMix],
	props: {
		curTabName: String,
	},
	data() {
		return {
			// pressureTapWidth: 0,
			// pressureTapHeight: 0,
			activeForm: 'chart', //list-列表 chart-图表
		}
	},
	watch: {
		curTabName: {
			immediate: true,
			handler(val) {
				if (val === 'ChannelInformation' && isBlank(this.params.time)) {
					// this.pressureTapWidth =
					//   this.$refs["pressure-tap-echarts-box"].offsetWidth;
					// this.pressureTapHeight =
					//   this.$refs["pressure-tap-echarts-box"].offsetHeight < 400
					//     ? 400
					//     : this.$refs["pressure-tap-echarts-box"].offsetHeight;
					this.$nextTick(() => {
						this.getData(this.activeChart)
					})
				}
			},
		},
	},
	created() {},
	mounted() {},
	methods: {
		isBlank,
		// 取压口图表渲染
		pressureTapCharts() {
			let myChart = echarts.init(document.getElementById('pressure' + this.deviceId))
			// 颜色数组
			let color = [
				'#4D6BFF',
				'#3BBE40',
				'#FF85D5',
				'#FFCD1B',
				'#EB5E5E',
				'#FF8933',
				'#FFCD1B',
				'#3BBE40',
				'#008C6B',
				'#33CEFF',
				'#336CFF',
				'#A056FF',
				'#DB4BFF',
				'#F85231',
				'#F55231',
				'#FFB320',
			]
			//横坐标数据
			let xData = []
			this.deviceDataList.forEach(item => {
				Object.keys(item.status).map(key => {
					let timeArr = item.status[key].map(m => {
						return m.time
					})
					xData.push(...timeArr)
				})
			})
			xData = [...new Set(xData)].sort(function (a, b) {
				return new Date(a).getTime() - new Date(b).getTime()
			})
			//数据点
			let seriesData = []
			this.deviceDataList.forEach(item => {
				Object.keys(item.status).map(key => {
					let dataY = xData.map(el => {
						let findItem = item.status[key].find(t => t.time === el)
						return findItem ? findItem.value : ''
					})
					seriesData.push({
						name: item.pressureStationName + (key === 'gasTemperature' ? '温度' : '压力'),
						type: 'line',
						// stack: "Total",//数据堆叠
						symbol: 'circle',
						symbolSize: 11,
						yAxisIndex: key === 'gasTemperature' ? 1 : 0,
						data: dataY,
						connectNulls: true,
					})
				})
			})
			seriesData = seriesData.map((item, index) => {
				return {
					...item,
					itemStyle: {
						color: color[index] ? color[index] : color[index % color.length],
					},
					lineStyle: {
						width: 2,
						color: color[index] ? color[index] : color[index % color.length],
					},
				}
			})
			let option = (option = {
				tooltip: {
					trigger: 'axis',
					show: true,
					formatter: params => {
						let str = ''
						let xLabel = ''
						params.forEach((item, index) => {
							xLabel = item.axisValue
							str +=
								item.value !== ''
									? (str !== '' ? '<br/>' : '') +
									  `<span style='display:inline-block;width:auto'>
                <span style='display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
					color[index] ? color[index] : color[index % color.length]
				};'></span>
                <span style='margin-right:5px;'>${item.seriesName}</span>
                </span> ${item.value + (item.seriesName.indexOf('压力') != -1 ? this.pressureUnitName : '℃')}`
									: ''
						})
						return xLabel + '<br/>' + str
					},
				},
				color: color,
				legend: {
					icon: 'rect',
					type: 'scroll',
					left: '65%',
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: xData,
				},
				yAxis: [
					{
						type: 'value',
						name: '单位：Kpa',
						// nameTextStyle: {
						//   align: "right",
						// },
					},
					{
						type: 'value',
						name: '温度：℃',
					},
				],
				series: seriesData,
				dataZoom: [
					{
						type: 'slider',
						show: true,
						bottom: '7px',
					},
				],
			})
			myChart.clear()
			myChart.setOption(option)
			//监听元素变化
			let erd = elementResizeDetectorMaker()
			let that = this
			erd.listenTo(document.getElementById('pressure' + this.deviceId), function () {
				that.$nextTick(function () {
					//使echarts尺寸重置
					myChart.resize({})
				})
			})
			// addEventListener("resize", () => {
			//   myChart.resize({
			//     width: this.$refs["pressure-tap-echarts-box"].offsetWidth,
			//     height:
			//       this.$refs["pressure-tap-echarts-box"].offsetHeight < 400
			//         ? 400
			//         : this.$refs["pressure-tap-echarts-box"].offsetHeight,
			//   });
			// });
		},
	},
	computed: {
		pressureUnitName() {
			return this.$store.getters.pressureUnitName
		},
	},
}
</script>
<style lang="scss" scoped></style>
